import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div className="section">
        <h1 className="title is-size-3">NOT FOUND</h1>
        <p>We can't find that page, sorry :(</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
